import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import Form from '../../../../src/containers/LandingForm'
import BottomMan from '../../images/bottom-man.png'
import Recommended from '../../images/recommended.png'
import WhiteBG from '../../images/white-bg.jpeg'
import FormStyle from '../../styles/SimpleForms'

const BottomFormWrapper = styled.div`
    background-image: url(${WhiteBG});
    background-size: cover;
    background-position: center;
    padding: 70px 0;
    position: relative;
    span {
        color: white;
    }
    > div {
        position: relative;
    }
    > div > img {
        bottom: -69px;
        left: -300px;
        position: absolute;
        width: 300px;
        @media (max-width: 667px) {
            display: none;
        }
    }
`

const BottomForm = () => (
    <BottomFormWrapper>
        <div className="form">
            <Grid container spacing={2}>
                <Grid item xs={3} style={{ width: '90px' }}>
                    <img style={{ width: '100%' }} src={Recommended} />
                </Grid>
                <Grid item xs={9} style={{ color: 'white' }}>
                    <h2>OBTENEZ UNE SOUMISSION GRATUITE</h2>
                    <p>
                        Remplissez simplement ce formulaire et un avocat réputé
                        de votre région vous contactera dans les plus brefs
                        délais.
                    </p>
                </Grid>
                <Grid item xs={12} style={{ padding: 0 }}>
                    <Form
                        simple={true}
                        source="AVO2"
                        className="soum-avo-form"
                    />
                </Grid>
            </Grid>
            <img src={BottomMan} />
        </div>

        <FormStyle />
    </BottomFormWrapper>
)

export default BottomForm
