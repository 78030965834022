import React from 'react'
import Layout from '../Layout'
import { Link } from 'gatsby'
import styled from 'styled-components'
import UpperForm from '../UpperForm'
import BottomForm from '../BottomForm'
const Content = styled.article`
    max-width: 1160px;
    margin: 0 auto;
    h1 {
        text-align: center;
        font-size: 3.5rem;
        font-weight: 600;
        line-height: 55px;
        @media (min-width: 801px) {
            && {
                font-size: 5rem;
                margin: 4rem 10% 3rem;
            }
        }
        @media (max-width: 767px) {
            font-size: 2.5rem;
        }
    }
    h2 {
        //margin: 60px 0 20px;
        text-transform: none;
    }
    h1,
    h2 {
        text-align: left;
    }
    p {
        text-align: justify;
    }

    p,
    strong,
    li,
    a {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
    }
    ul,
    ol {
        padding-left: 2rem;
        list-style: circle;
    }
    margin-top: 20px;
    @media (min-width: 801px) {
        margin-top: -190px;
        img {
            margin-bottom: 2rem;
        }
    }
    @media (max-width: 768px) {
        padding: 12px;
        h1 {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
    }
`

const CTA = styled.div`
    margin: 40px auto;
    max-width: 400px;
    @media (max-width: 767px) {
        margin: 15px auto;
    }
`

export default ({ location, description, title, children, image }) => (
    <Layout
        location={location}
        title={title}
        description={description}
        noPad={true}
        image={image}
    >
        <UpperForm notMainContent />
        <Content>
            {children}

            {/* <CTA><Link to="/demande"><ButtonWithArrow>Trouver mon avocat</ButtonWithArrow></Link></CTA> */}
        </Content>
        <BottomForm />
    </Layout>
)
