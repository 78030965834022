import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import Form from '../../../../src/containers/LandingForm'
import Recommended from '../../images/recommended.png'
import CreepyDude from '../../images/top-man.png'
import FormStyle from '../../styles/SimpleForms'

const UpperForm = ({ homepage }) => {
    const [expanded, setExpanded] = useState('')
    const handleExpand = () => {
        setExpanded('expanded')
    }
    return (
        <>
            <div
                className={`background-container ${
                    homepage ? 'homepage' : ''
                } ${expanded}`}
            >
                <div className="form-container">
                    <h1>
                        Trouvez Rapidement un <strong>Avocat Compétent</strong>{' '}
                        pour
                        <br />
                        Vos Affaires Personnelles ou d’Entreprise
                    </h1>
                    <div className="form">
                        <img className="top-man" src={CreepyDude} />
                    </div>
                </div>
            </div>

            <div className="floating-form">
                <Grid container spacing={2}>
                    <Grid item xs={3} style={{ width: '90px' }}>
                        <img style={{ width: '100%' }} src={Recommended} />
                    </Grid>
                    <Grid item xs={9} style={{ color: 'white' }}>
                        <h2>OBTENEZ UNE SOUMISSION GRATUITE</h2>
                        <p>
                            Remplissez simplement ce formulaire et un avocat
                            réputé de votre région vous contactera dans les plus
                            brefs délais.
                        </p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0 }}>
                        <Form
                            simple={true}
                            className="soum-avo-form"
                            source="AVO2"
                            expandCallback={handleExpand}
                        />
                    </Grid>
                </Grid>
            </div>
            <FormStyle />
        </>
    )
}

export default UpperForm
