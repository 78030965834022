

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/comment-debarrasser-accusation-vol-scaled.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost5 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Comment vous débarrasser d’une accusation de vol au Québec? Vol à l’étalage et vol qualifié! - Soumissions Avocat"
        description="Trouvez un avocat en droit criminel pour vous débarrasser de vos accusations de vol avec l’aide de Soumissions Avocat!"
        image={LeadImage}>
        <h1>Comment vous débarrasser d’une accusation de vol au Québec? Vol à l’étalage et vol qualifié!</h1>

					
					
								
						
							
							
						
							
					
			
							
						
				
					
			<h4>SOMMAIRE</h4>					
		
			
				
			
		
				
				
						
					
		
								
					
		
				
						
							
					
			
							
						
				
								
					<p><strong>L’infraction de vol apparaît parmi les crimes les plus fréquemment rapportés par la police au Canada.</strong> Comme il s’agit d’une infraction dont la définition est très large, un grand nombre de comportements interdits peuvent se retrouver sous ce chef d’accusation. Ce pourrait bien être votre cas si vous éprouvez des démêlés avec la justice! </p>
<p><StaticImage alt="comment debarrasser accusation vol" src="../images/comment-debarrasser-accusation-vol-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Saviez-vous qu’il existe plusieurs types de vols au Canada et au Québec?</strong> Plus intéressant encore, étiez-vous au courant du fait que les peines d’emprisonnement pour une infraction de vol varient entre l’absolution (absence de casier) et la <strong>prison à perpétuité!</strong> C’est donc dire que vous pourriez repartir libre comme l’air ou encore passer le reste de vos jours derrière les barreaux pour une accusation de vol…</p>
<p><strong>Cela vous motive à comprendre l’infraction de vol dont VOUS êtes accusé et à trouver un avocat criminaliste compétent?</strong> Vous êtes au bon endroit, car Soumissions Avocat vous explique précisément toutes les infractions de vol ainsi que les différentes peines applicables!</p>
<h2>Qu’est-ce qu’une accusation de vol?</h2>
<p>L’accusation de vol est d’abord et avant tout un crime! C’est donc dire que tous les éléments constitutifs de l’accusation doivent être prouvés <strong>hors de tout doute raisonnable</strong>, et ce, par le procureur des poursuites criminelles et pénales. Une accusation de vol doit retrouver un élément matériel (l’acte illicite) ainsi qu’un élément moral (l’esprit coupable) afin de tenir la route en cour!</p>
<p><strong>L’élément matériel du vol (L’actus reus), </strong>consiste à prendre, détourner ou encore priver une personne (la victime) d’un bien sur lequel elle possède un droit. Le vol doit donc priver le propriétaire du bien et c’est pourquoi le simple fait de déplacer un bien peut constituer un vol.</p>
<p><strong>L’élément moral du vol (la mens rea), </strong>réfère plutôt à l’intention de commettre le crime. Cette intention doit d’ailleurs être spécifique et viser à priver le propriétaire de son bien. Elle doit également avoir pour but de priver frauduleusement ce même propriétaire de son droit. Sans cette intention, il sera impossible de conclure à la présence d’un vol!</p>
<p><strong>L’infraction de vol est un crime d’intention spécifique! </strong>L’accusé doit donc viser précisément à priver un propriétaire de son bien tout en sachant qu’il ne détient aucun droit à l’égard du bien volé.</p>
<p>La loi ne punit pas les gens qui prennent des biens par mégarde! En effet, si un accusé s’accapare un bien, mais qu’il n’avait pas l’intention de le voler ou qu’il croyait qu’il s’agissait de son propre bien, on ne sera pas en présence d’un vol.</p>
<p><strong>L’intention de voler est tout aussi importante que le fait de voler en droit criminel!</strong></p>
<h2>Vol de plus de 5000$ ou vol de moins de 5000$ : quelle différence?</h2>
<p>La première façon de départager les accusations de vol consiste à les classer selon la valeur du bien qui a été pris sans droit (volé). La loi fait d’abord une différence entre les biens volés qui ont une valeur inférieure à 5000$ et les biens volés qui ont une valeur de plus de 5000$.</p>
<p><StaticImage alt="vol etalage qualifie simple" src="../images/vol-etalage-qualifie-simple-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Quelle est la différence entre ces deux chefs d’accusation? La peine que vous risquez! </strong>En effet, la limite de 5000$ a une incidence importante sur la gravité de l’infraction et sur la durée d’emprisonnement que vous risquez de recevoir. Il en est ainsi parce que l’infraction de vol pour moins de 5000$ est une infraction hybride.</p>
<p>Cela permet au Procureur des poursuites criminelles et pénales de procéder par voie d’accusation sommaire (peines plus clémentes), tandis que le vol de plus de 5000$ entraîne la mise en accusation par voie criminelle (conséquences plus graves).</p>
<h2>Avez-vous vraiment commis un vol à l’étalage? Évitez un dossier criminel!</h2>
<p><strong>De prime abord, l’infraction de vol à l’étalage n’apparaît pas spécifiquement au Code criminel. </strong>Au sens de la loi, le vol à l’étalage est un simple vol qui doit être classé selon sa valeur (moins de 5000$ ou plus de 5000$), tel qu’expliqué dans la section précédente. En quoi consiste un vol à l’étalage dans ce cas?</p>
<p><strong>Pour qu’un vol soi qualifié comme « à l’étalage », il doit se produire dans ce contexte :</strong></p>
<p>-Commis dans un magasin/boutique/commerce</p>
<p>-L’accusé ne devait pas être à l’emploi du commerce</p>
<p>-Les biens doivent avoir été pris sans violence</p>
<p>-L’accusé devait se trouver légalement dans le magasin</p>
<p>Encore une fois, le fardeau de preuve en droit criminel ne change pas et tous ces éléments doivent être prouvés hors de tout doute raisonnable!</p>
<p><strong>Il s’agit de votre première offense criminelle? </strong>Le vol à l’étalage fait partie des infractions admissibles au traitement non judiciaire en cas d’accusations criminelles. Ce programme pourrait vous offrir une peine beaucoup plus clémente qu’un procès criminel et vous pourriez y être admissible s’il s’agit de votre première infraction pour vol à l’étalage.</p>
<h2>Vol qualifié, est-ce que vous risquez des conséquences importantes?</h2>
<p><strong>On entend souvent dire qu’un vol qualifié doit toujours commis avec une arme, mais c’est faux! </strong>Cette infraction de vol est la plus sérieuse qui apparaît au Code criminel en raison de son caractère violent et de son atteinte à l’intégrité physique et psychologique d’autrui.</p>
<p><strong>Il existe en réalité quatre façons de commettre un vol qualifié!</strong></p>
<p>1) Un vol commis en proférant des menaces ou en utilisant la violence.</p>
<p><strong>2) Commettre des actes de violence contre la victime (avant ou après le vol).</strong></p>
<p><strong>3) Commettre des voies de fait dans le but de voler quelqu’un.</strong></p>
<p><strong>4) Voler en utilisant une arme ou une imitation d’arme.</strong></p>
<p>Pouvez-vous être reconnu coupable de vol même si vous échouez? Tout à fait! En analysant les quatre conditions mentionnées ci-haut, on constate que le fait de commettre des voies de fait avec une intention de voler peut suffire à constituer une infraction pour vol qualifié. Vous avez bien lu, ce principe est vrai <strong>même si rien n’a été volé au final!</strong></p>
<p><strong>Qu’est-ce qu’une « arme » selon la définition du droit criminel? </strong>Une arme est loin de se limiter aux armes à feu ou aux couteaux selon la loi. En effet, la définition est plus englobante et comprend tout objet utilisé dans le but de causer un préjudice, des blessures ou encore proférer des menaces à autrui.</p>
<p><strong>Le vol qualifié est un crime TRÈS grave! Si vous en êtes la cible, assurez-vous de contacter un avocat qualifié en droit criminel sans tarder, car le temps presse!</strong></p>
<h2>Pourriez-vous être accusé de recel en plus de vol?</h2>
<p><strong>Qu’est-ce que le recel? </strong>Le recel est une infraction criminelle différente du vol, mais dont les conséquences sont très similaires. En effet, le recel consiste à être en possession de biens provenant de la commission d’un acte criminel. Ce serait le cas d’un individu qui, sans avoir lui-même volé un bien, le garde en possession en sachant très bien qu’il a été volé.</p>
<p><StaticImage alt="avocat recel vol defense" src="../images/avocat-recel-vol-defense-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Sachez que pour constituer un crime de recel, la personne ne doit pas nécessairement avoir la possession physique d’un bien, mais plutôt détenir un degré de contrôle sur celui-ci. Le bien peut même être détenu par l’intermédiaire d’un tiers.</p>
<p><strong>Quelles sont les peines possibles pour le recel? </strong>Le receleur s’expose aux mêmes peines que l’individu reconnu coupable d’un vol simple. C’est encore une fois la valeur des biens qui déterminera la peine applicable (moins de 5000$ ou plus de 5000$).</p>
<h2>Quelles peines risquez-vous pour une accusation de vol?</h2>
<p>Vous pensez que toutes les infractions de vol se terminent avec une simple tape sur les doigts de l’accusé? Détrompez-vous, le vol est une infraction très grave au sens du Code criminel et la loi prévoit des <strong>peines d’emprisonnement sévères</strong> à l’endroit des individus coupables. Quel châtiment recevrez-vous en cas de verdict de culpabilité pour vol?</p>
<p><strong>Voici les peines qui pourraient vous être imposées selon le type de vol dont vous êtes reconnu coupable!</strong></p>
<p><strong> </strong></p>
<table>
<tbody>
<tr>
<td><p><strong> </strong></p>
<p><strong> </strong><strong>Type d’accusation criminelle</strong></p>
<p> </p></td>
<td><p><strong> </strong></p>
<p><strong>Mise en accusation par voie sommaire</strong></p></td>
<td><p><strong> </strong></p>
<p><strong>Mise en accusation Acte criminel</strong></p></td>
<td><p><strong> </strong></p>
<p><strong> </strong><strong>Absolution ou sursis possible?</strong></p>
<p><strong> </strong></p></td>
</tr>
<tr>
<td><p><strong> </strong></p>
<p><strong>Vol de moins de 5000$</strong></p>
<p><strong> </strong></p></td>
<td><p>Emprisonnement maximal de 6 mois</p>
<p>ET/OU</p>
<p>Amende de 5000$</p></td>
<td><p>Emprisonnement maximal de 2 ans</p>
<p> </p></td>
<td><p>Oui</p></td>
</tr>
<tr>
<td><p><strong> </strong><strong>Vol de plus de 5000$</strong></p></td>
<td><p><strong>Non-applicable</strong></p>
<p> </p></td>
<td><p>Emprisonnement maximal de 10 ans</p></td>
<td><p>Oui</p></td>
</tr>
<tr>
<td><p><strong>Vol d’un véhicule</strong><strong> </strong></p></td>
<td><p>Peine maximale de 18 mois</p></td>
<td><p>Peine minimale de 6 mois (si récidive)</p>
<p>Peine maximale de 10 ans</p>
<p> </p></td>
<td><p>Oui</p></td>
</tr>
<tr>
<td><p><strong>Vol qualifié</strong><strong> </strong></p></td>
<td><p><strong>Non-applicable</strong></p></td>
<td><p>Peine minimale de 4 ans</p>
<p>Emprisonnement maximal à perpétuité</p>
<p> </p></td>
<td><p>Non</p></td>
</tr>
</tbody>
</table>
<p><strong> </strong></p>
<p><strong>Mise en accusation criminelle vs. accusation par voie sommaire, quelle différence? </strong>L’accusation par voie sommaire s’applique lorsqu’une infraction est « hybride », c’est-à-dire, lorsque son niveau de gravité est “relativement faible” et que le procureur aux poursuites criminelles et pénales (DPCP) peut choisir s’il procède par voie criminelle ou sommaire. La voie sommaire possède le net avantage d’imposer des peines plus clémentes que l’accusation criminelle, mais cette décision revient au DPCP!</p>
<p><strong>Qu’est-ce que l’absolution lors d’une condamnation pour vol? </strong>L’absolution est en réalité une peine prévue au Code criminel applicable lorsqu’une personne est déclarée (ou plaide) coupable d’une infraction. Celle-ci a pour effet de ne pas imposer un casier judiciaire à l’accusé, en plus de ne recevoir aucune peine d’emprisonnement ni d’amende. Elle peut être conditionnelle ou inconditionnelle selon la situation de l’accusé et la gravité du vol reproché.</p>
<p><strong>Pourquoi le vol qualifié est-il uniquement employé par voie criminelle? </strong>En raison de la gravité du vol! Comme l’infraction est commise en causant des voies de fait ou en utilisant une arme, le niveau de gravité est nettement plus élevé que les autres types de vol, ce qui empêche la procédure par voie sommaire. Évidemment, les conséquences sont plus importantes pour un vol qualifié, puisque la peine maximale est l’emprisonnement à perpétuité.</p>
<h2>Quelle sentence sera appliquée à VOTRE accusation de vol?</h2>
<p><strong>Lorsqu’il y a une peine minimale, le juge ne peut ordonner une peine inférieure! </strong>Tel qu’illustré dans le tableau ci-haut, il en est ainsi pour le vol qualifié ou encore pour le vol d’un véhicule en cas de récidive. Toutefois, ce ne sont pas toutes les accusations de vol qui viennent avec une peine minimale.</p>
<p><StaticImage alt="peine emprisonnement vol avocat" src="../images/peine-emprisonnement-vol-avocat-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>En l’absence d’un tel critère de peine, le juge devra appliquer les principes de détermination de la peine à votre accusation de vol soit :
</strong>
<strong>-La peine doit être individualisée</strong></p>
<p>-La peine doit être proportionnelle</p>
<p>-La peine doit tenir compte des circonstances</p>
<p>-La peine doit mettre en balance la gravité et la responsabilité de l’accusé</p>
<p>Comme vous le voyez, même en cas de verdict de culpabilité, <strong>tout peut encore arriver au niveau de la gravité de votre peine</strong>. C’est le travail de votre avocat d’effectuer une représentation sur la peine adéquate ou encore de négocier une peine clémente avec la poursuite.</p>
<h2>Êtes-vous admissible au traitement non judiciaire ou aux mesures de rechange?</h2>
<p>Pour un accusé, la meilleure façon de tirer avantage du système judiciaire criminel est de l’éviter autant que possible. Plus facile à dire qu’à faire lorsqu’on vous accuse formellement d’une infraction criminelle vous direz, mais sachez qu’il existe désormais deux programmes « déjudiciarisés » permettant de traiter des infractions criminelles en dehors des tribunaux.</p>
<p><strong>Pour bénéficier de cette « justice alternative », votre accusation de vol doit cependant être éligible! Seules les accusations de vol suivantes sont admissibles :</strong></p>
<p>-Vol de moins de 5000$</p>
<p>-Vol de cartes de crédit</p>
<p>-Tentative de vol (valeur inférieure à 5000$)</p>
<p>-Recel</p>
<p><strong>
Qu’est-ce que le traitement non judiciaire d’une infraction de vol?</strong> C’est le Directeur des poursuites criminelles et pénales qui doit choisir de diriger le dossier d’un individu vers ce programme plutôt que vers les tribunaux criminels. Cette décision de prend d’abord en considérant si l’infraction admissible et en analysant les antécédents criminels de l’accusé. Il s’agit d’une façon pour l’accusé d’éviter un procès criminel ainsi que les conséquences qui pourraient en découler.</p>
<p><strong>Quelles sont les conséquences du traitement non judiciaire? </strong>L’accusé, au lieu de devoir subir un procès, reçoit plutôt une<strong> lettre d’avertissement</strong> qui l’informe que le DPCP a choisi de le référer au programme et de ne pas porter d’accusations criminelles contre lui, mais qu’il y a suffisamment de preuve pour le faire.</p>
<p>Cette lettre informe également l’individu qu’il a le droit de consulter un avocat et qu’il ne doit pas commettre une nouvelle infraction criminelle dans les 5 prochaines années, sous peine de voir son dossier.<strong>
</strong>
<strong>Programme de mesures de rechange général pour adultes, une façon de déjudiciariser un dossier criminel! </strong>Les mesures de rechange diffèrent du traitement non judiciaire, puisque la personne doit reconnaître sa culpabilité concernant l’infraction reprochée (ce qui n’est pas le cas du traitement non judiciaire). Encore une fois, c’est le DPCP qui détermine l’admissibilité d’un individu à ce programme.</p>
<p><strong>Quelles sont les mesures de rechange ordonnées? </strong>Celles-ci peuvent prendre plusieurs formes, mais elles incluent principalement des mesures de réparation envers les victimes du crime en tenant compte de leurs intérêts et de ceux de la collectivité.</p>
<h2>Pourquoi devez-vous engager un avocat rapidement suite à une accusation de vol?</h2>
<p>Les accusations criminelles de vol et tout le processus qui les entourent forment une situation angoissante, et cela se comprend. Toutefois, en étant accompagné d’un avocat compétent et d’expérience, vous augmentez vos chances d’obtenir un verdict favorable. Voici pourquoi les services d’un avocat en droit criminel sont indispensables pour une accusation de vol!</p>
<p><strong>Tenter de négocier une entente ou l’abandon des accusations de vol.
</strong>
<strong>Pour maximiser vos chances d’obtenir un verdict de non-culpabilité
</strong>
<strong>En cas de culpabilité, lors des représentations sur la peine!</strong></p>
<h2>Contactez Soumissions Avocat dès maintenant pour vous défendre contre vos accusations de vol!</h2>
<p>Les conséquences d’une infraction de vol peuvent être extrêmement sévères et mener à l’emprisonnement, ou encore, elles peuvent être réduites au minimum avec l’aide d’un avocat spécialisé en droit criminel! À vous de voir si vous souhaitez mettre toutes les chances de votre côté d’obtenir un verdict de non-culpabilité ou du moins, une peine clémente dans le cas contraire.</p>
<p>Quoi qu’il en soit, seul un avocat en droit criminel sera en mesure de vous représenter adéquatement lorsque des accusations de vol sont portées contre vous!</p>
<p><strong>Pour qu’un tel professionnel vous vienne en aide, il suffit de remplir le formulaire en bas de page afin d’être mis en contact gratuitement avec un avocat en droit criminel.</strong></p>
<p><strong>Qu’attendez-vous pour vous défendre contre ces accusations? Contactez Soumissions Avocat, nos services sont extrêmement efficaces et sans engagement!</strong></p>
<p> </p>
    </SeoPage>
)
export default BlogPost5
  