import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledLink = styled(Link)`
    background-color: #61ce70;
    border-radius: 4px;
    color: white;
    display: block;
    font-weight: 600;
    padding: 15px 40px;
    margin: 10px auto;
    max-width: 600px;
    text-align: center;
`

const ConversionLink = ({ to, text, isButton }) => {
    if (isButton) {
        return <Link to={to}>{text}</Link>
    }
    return <StyledLink to={to}>{text}</StyledLink>
}

export default ConversionLink
