import { createGlobalStyle } from 'styled-components'

import Background from '../images/top-bg.jpeg'

const ConversionFormStyles = createGlobalStyle`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : '#0c3562'};
    max-width: 100%;
    padding: 0;
    .form-container {
        margin: 0 auto;
        padding-top: 10px;
        position: relative;
        max-width: 960px;
    }
    .floating-form,
    .form {
        background-color: #0d2035;

        padding: 15px 15px 15px 20px;

        margin: 0 auto;
        
        h2 {
            font-size: 18px;
            line-height: 22px;
        }
        h2,
        p {
            margin: unset;
        }
        button {
            font-size: 12px;
        }
        div {
            button {
                height: 70px;
            }
        }
        label {
            font-size: 16px;
        }
        @media (max-width: 667px) {
            min-height: unset;
            width: 100%;
        }
    }
    .form {
        @media (min-width: 668px) {
            min-height: 667px;
            width: 650px;
        }
    }
    .floating-form {
        position: absolute;
        left: 50%;
        margin-left: -315px !important;
        top: 285px;
        width: 630px;
        h2 {
            padding: 0;
        }
        span {
            color: white !important;
        }
        .days {
            color: white;
        }
        @media (max-width: 667px) {
            position: unset;
            margin: unset !important;
            margin-top: -2px;
            left: unset;
            width: 100%;
            p {
                line-height: 1;
            }
        }
    }
    .form-container .form.alternate {
        background: linear-gradient(#fff, #d2e1ea);
        width: 700px;
        .intro {
            display: flex;

            min-height: 800px;
            padding: 100px 30px 50px;
            width: 100%;
            > div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
            button {
                border-radius: 4px !important;
                padding: 15px 10px;
                span {
                    font-size: 18px;
                    font-weight: 600;
                }
            }
            p {
                color: #646464;
                text-align: center;
            }
            .cta {
                padding: 20px 0;
                position: relative;
                img {
                    position: absolute;
                    top: 0px;
                }
                @media (max-width: 667px) {
                    button {
                        > span {
                            font-size: 1.2rem;
                        }
                    }
                    img {
                        top: 30px;
                        width: 60px;
                    }
                }
            }
            @media (max-width: 667px) {
                padding: 50px 30px;
                h1 {
                    margin-right: 30px;
                }
            }
        }
        #submit-button {
            margin: 0 auto;
        }
        @media (max-width: 667px) {
            overflow-x: hidden;
            width: 100%;
            .soum-avo-form {
                height: unset;
            }
            .MuiButton-label {
                font-size: 0.8rem;
            }
            #submit-button.MuiButton-label {
                font-size: 1.2rem;
            }
        }
    }
    .background-container.expanded {
        margin-bottom: 700px;
        @media (max-width: 667px) {
            margin-bottom: 0;
        }
    }
    .background-container {
        height: 950px;

        .top-man,
        .frisou {
            bottom: 45px;
            left: -130px;
            position: absolute;
            width: 300px;
        }
        background-color: #0d2035;
        @media (max-width: 667px) {
            height: 0px;
            margin-bottom: -1px;
            .frisou {
                left: unset;
                bottom: unset;
                right: -20px;
                top: 70px;
                width: 100px;
            }

            .top-man {
                display: none;
            }
        }
        @media (max-width: 768px) {
            .frisou.hide-mobile {
                display: none;
            }
            .top-man {
                display: none;
            }
            height: 1030px;
        }
        clip-path: polygon(0 0, 100% 0, 100% 58%, 50% 80%, 0 58%);
        //@media (min-width: )
        @media (max-width: 667px) {
            background: linear-gradient(#f5b271, #a2bdcf);
            clip-path: unset;
            height: unset;
        }
    }
    .background-container::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(${Background});
        background-position: center 120px;
        background-size: cover;
        height: 930px;
        @media (max-width: 667px) {
            background-image: none;
            content: unset;
        }
        //clip-path: polygon(100% 0, 100% 78%, 50% 99%, 0 78%, 0 0);
    }
    .simple-form {
        height: unset;
        > div {
            min-height: unset;
            border: 0;
            background-color: transparent;
            padding-top: 0;
        }
        .MuiInputBase-input {
            background: white;
            legend {
                font-size: 0.65em !important;
            }
        }
        .MuiOutlinedInput-notchedOutline {
            padding: 0;
            legend {
                font-size: 0.7em;
            }
        }
        .MuiInputLabel-shrink {
            color: white;
            transform: translate(1px, -12px) scale(0.75);
        }
        .MuiFormHelperText-contained.Mui-error {
            font-size: 0.65rem;
        }
        .MuiInputBase-multiline {
            background-color: white;
        }
    }
    .dynamic-form {
        padding: 20px;
        height: 800px;
        > div {
            border: 0;
            background-color: transparent;
            h2 {
                color: #0d2035;
                font-weight: 600;
            }
            > div {
                justify-content: center;
                padding: 5px;
            }
        }
        .step-buttons {
            margin-top: 20px;
            button {
                font-size: 1rem;
                height: 35px;
                margin: 0 20px;
                background-color: #0c3562;
                color: white;
            }
        }
        @media (max-width: 667px) {
            padding: 20px 0;
            > div {
                padding: 20px 0;
            }
        }
    }
`

export default ConversionFormStyles
